import * as SwitchPrimitives from '@radix-ui/react-switch';
import {VariantProps, cva} from 'class-variance-authority';
import * as React from 'react';

import {cn} from '~/components/utils';
export const variants = cva('bg-input', {
  variants: {
    variant: {
      primary: 'data-[state=checked]:bg-primary',
      secondary: 'data-[state=checked]:bg-secondary',
      tertiary: 'data-[state=checked]:bg-tertiary',
      quaternary: 'data-[state=checked]:bg-quaternary',
      'reverse-primary': '',
      'reverse-secondary': '',
      'reverse-tertiary': '',
      'reverse-quaternary': '',
    },
  },
  defaultVariants: {
    variant: 'primary',
  },
});

export const dotVariants = cva(
  'pointer-events-none block h-5 w-5 rounded-full bg-background shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0',
  {
    variants: {
      variant: {
        primary: '',
        secondary: '',
        tertiary: '',
        quaternary: '',
        'reverse-primary': 'data-[state=checked]:bg-primary',
        'reverse-secondary': 'data-[state=checked]:bg-secondary',
        'reverse-tertiary': 'data-[state=checked]:bg-tertiary',
        'reverse-quaternary': 'data-[state=checked]:bg-quaternary',
      },
    },
    defaultVariants: {
      variant: 'primary',
    },
  },
);

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> & VariantProps<typeof variants>
>(({className, name, onCheckedChange, variant, ...props}, ref) => {
  const [localChecked, setLocalChecked] = React.useState(props.checked ?? props.defaultChecked ?? false);

  const finalChecked = props.checked ?? localChecked;

  const finalOnChange = (v: boolean) => {
    if (onCheckedChange) {
      onCheckedChange(v);
    }
    setLocalChecked(v);
  };

  return (
    <>
      <input type="hidden" name={name} value={finalChecked ? 'true' : 'false'} />
      <SwitchPrimitives.Root
        onCheckedChange={finalOnChange}
        className={cn(
          'peer inline-flex h-6 my-2 w-11 shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 data-[state=unchecked]:bg-input',
          variants({variant}),
          className,
        )}
        {...props}
        ref={ref}>
        <SwitchPrimitives.Thumb className={cn(dotVariants({variant}))} />
      </SwitchPrimitives.Root>
    </>
  );
});
Switch.displayName = SwitchPrimitives.Root.displayName;

export {Switch};
